import React, {ReactElement, useEffect} from 'react';
import {useAuth} from "../context/authContext";
import {Link, useNavigate} from "react-router-dom";
import {
    FaBusAlt, FaCar,
    FaClipboard,
    FaDoorOpen,
    FaExchangeAlt,
    FaPlane,
    FaSignInAlt,
    FaUserGraduate,
    FaWarehouse
} from "react-icons/fa";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { CiBank } from "react-icons/ci";
import { FiPower } from 'react-icons/fi';
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FaBus } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import Card from "../components/Card";
// import img_hero from "../Rectangle 184.png";

export type Links = {
    title: string;
    link: string;
    icon: ReactElement
}

// @ts-ignore
// @ts-ignore
// @ts-ignore
const cardsData:Links[] = [
    { title: "IP Phone Extentions", link: "/ip-phones",icon:<TbDeviceLandlinePhone />},
    { title: "Leave Application", link: "https://docs.google.com/forms/d/e/1FAIpQLSevtr9TWQBsX6N-5na-cuVYWWcprKHrXHj_23bNUiFEhJCwNQ/viewform", icon: <FaClipboard />},
    { title: "Gate Pass", link: "https://docs.google.com/forms/d/e/1FAIpQLSdrpJhnADcyT5xXNeClZLatI7W4fCvtsYsf8iwYFTm2raM_8Q/viewform", icon: <FaSignInAlt /> },
    { title: "Bus Part Authorization", link: "https://docs.google.com/forms/d/e/1FAIpQLScN6K5v4yesirCwv9lKlmoV6fFlDz61t4ix1jdsGPRoAG6NeQ/viewform", icon: <FaBusAlt /> },
    { title: "Exit Protocols", link: "https://docs.google.com/forms/d/e/1FAIpQLSeN0EFyFKwJhDqOtIBponRDmlgRWM_ee0VU7iYk2urJFA8oKg/viewform", icon: <FaDoorOpen /> },
    { title: "Travel Authorization", link: "https://docs.google.com/forms/d/e/1FAIpQLSc3pbocZwMOmixlulgtnpsx2WvY3j3-utbXmgBsEPoBDr-vmw/viewform", icon: <FaPlane /> },
    { title: "Skilling and Capacity Development", link: "https://docs.google.com/forms/d/e/1FAIpQLSf2vKVMRxfp_0N1RhiQgZBybDlOT3noMrC7t5myvSuE14JPiw/viewform", icon: <FaUserGraduate /> },
    { title: "Asset Transfer", link: "https://forms.gle/PE8tX67BYwudAX7S7", icon: <FaExchangeAlt /> },
    { title: "Warehouse Production Planning and Control System", link: "https://docs.google.com/forms/d/e/1FAIpQLScVVVzB8tee0Ybi8jTnEaUVA6UiHuxPMbOUmOlzGSXDmBqEUQ/viewform", icon: <FaWarehouse /> },
    { title: "Vehicle Nomination Form", link: "https://docs.google.com/forms/d/e/1FAIpQLSfZOUqKGnDeam-4e_-rLKfb-KCit4oX-aDlT9Oks_mTirfBSA/viewform", icon: <FaCar /> },
    { title: "Performance Management System", link: "https://performance.kiiramotors.com/", icon: <BsGraphUpArrow /> },
    { title: "Bank Information Change", link: "https://forms.gle/uB9YyHWS7C6d8EUm7", icon: <CiBank /> },
    { title: "Production Process Portal ", link: "https://production-portal.kiiramotors.com/", icon: <FaBus /> },
    { title: "Production Process Portal Admin", link: "https://production-portal.kiiramotors.com/admin-vehicle-models", icon: <MdOutlineAdminPanelSettings /> },
   
    //{ title: "Records Management", link: "https://docs.google.com/forms/d/e/1FAIpQLSfZOUqKGnDeam-4e_-rLKfb-KCit4oX-aDlT9Oks_mTirfBSA/viewform", icon: <FaCar /> }
    
];


const Dashboard = () => {
    const {employeeI} = useAuth()
    const navigate = useNavigate();
    useEffect(() => {
        if (!employeeI) navigate("/login", { replace: true });
    })
    return (
        <div className={`w-full h-full flex flex-col items-center gap-2 md:gap-6`}>
            {/* <div className={`absolute left-8 top-8 w-fit h-fit px-4 py-1.5 ring-2 ring-orange-500 bg-orange-500 hover:bg-orange-600 flex flex-col items-center rounded-md text-white text-base font-semibold`}>
                <Link to={"/logout"}>Logout</Link>
            </div> */}
            <div className='w-full h-fit flex flex-row gap-4 justify-between items-center px-4 mt-4 md:mt-7'>
                <div className="w-fit h-fit">
                    <img
                    src="/New KMC Logo 1.png"
                    onClick={() => navigate('/')}
                    alt="Logo"
                    className="h-5 w-auto cursor-pointer"
                    />
                </div>
        
                <div
                    className="w-fit h-fit p-2 flex items-center justify-center cursor-pointer"
                    >
                    <Link to="/logout">
                        <FiPower className="w-6 h-6 text-gray-600 hover:text-red-600" />
                    </Link>
                </div>
            </div>
        




             {/* <div
                style={{ backgroundImage: "url('/Rectangle_184.png')", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}
                className="bg-cover w-full h-44 bg-center">
            </div>  */}

            <div className={`bg-[url("https://kmcwebsite.nyc3.cdn.digitaloceanspaces.com/kiis/Rectangle%20184.png")] md:bg-cover bg-no-repeat w-full h-44 bg-center`}></div> 
           

            <div className={`w-full h-fit flex flex-row items-center justify-center max-sm:px-4`}>
            <h1 className={`mt-24 md:mt-10 text-2xl lg:text-4xl`}><span className={`text-red-600 font-semibold`}>{employeeI?.fname}</span> welcome back to the KIIS.</h1>
            </div>
            
            
            <div className="mt-2 md:mt-10 w-full h-fit dashboard flex gap-4">
                
                {/* Cards Section */}
                <div className="w-full md:w-4/5 h-fit flex-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    
                    {cardsData.map((card, index) => {
                        if (card.title === "Production Process Portal Admin") {
                            if (employeeI?.email == "jnayiga@kiiramotors.com" || employeeI?.email == "pmuwanguzi@kiiramotors.com"||employeeI?.email =="pabenaitwe@kiiramotors.com"||employeeI?.email =="dtusiime@kiiramotors.com"||employeeI?.email =="gkitutu@kiiramotors.com"||employeeI?.email =="jarinaitwe@kiiramotors.com"||employeeI?.email =="cjnsubuga@kiiramotors.com") {
                                return (
                                    <Card key={index} data={card} />
                                )
                            } else {
                                return (
                                    <div></div>
                                )
                            }
                            
                        } else {
                            return (
                                <Card key={index} data={card} />
                            )
                        }



                    }
                        

                        
                    )}
                </div>

                {/* Announcements Section */}
                <div className={`max-sm:hidden w-1/5 min-h-56 h-fit flex flex-col rounded-md bg-[#E6E6E6]`}>
                    <div className={`w-full h-fit bg-black py-2 rounded-t-md`}>
                        <h1 className={`text-xl lg:text-3xl font-semibold text-center text-white sm:text-xs`}>Announcements</h1>
                    </div>
                    <div className="flex items-center justify-center h-full text-gray-500 mt-2.5">
                        No announcements available.
                    </div>
                </div>


            </div>

           

        </div>
    );
};

export default Dashboard;