import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import 'tailwindcss/tailwind.css';

interface PhoneEntry {
  sn: number;
  name: string;
  //acronym: string;
  office: string;
  extension: number;
  department: string;
  division: string;
}


const ipPhoneData: PhoneEntry[] = [
  { sn: 1, name: 'SANDY STEVENS TICKODRI-TOGBOA',  office: 'E-403', extension: 2003,department:'Executive Director',division:'N/A' },
  { sn: 2, name: 'PAUL ISAAC MUSASIZI',  office: 'E-401', extension: 2002,department:'Office of the CEO',division:'N/A' },
  { sn: 3, name: 'IMMACULATE KARUNGI',  office: 'E-402', extension: 2190,department:'Office of the CEO',division:'OCEO' },
  { sn: 4, name: 'LILLIANN NABBAALE',  office: 'E-405', extension: 2158,department:'Office of the CEO',division:'OCEO' },
  { sn: 5, name: 'ALBASHIR LUBANGA',  office: 'E-406', extension: 2123,department:'Office of the CEO',division:'OCEO' },
  //{ sn: 6, name: 'ARTHUR TUMUSIIME ASIIMWE', office: 'E-306', extension: 2102,department:'Finance and Planning',division:'N/A' },
 // { sn: 7, name: 'AGNES KABATESI',  office: 'E-303', extension: 2112,department:'Human Resources and Administration',division:'Facilities Management' },
  { sn: 8, name: 'PETER ALBERT KYEYUNE',  office: 'E-303', extension: 2116,department:'Finance and Planning',division:'Procurement and Supply Management' },
  { sn: 9, name: 'RICHARD MUSANNYANA',  office: 'E-304', extension: 2095,department:'Finance and Planning',division:'Finance and Accounting' },
  { sn: 10, name: 'DEBRAH ATWIJUKIRE', office: 'E-304', extension: 2061,department:'Finance and Planning',division:'Finance and Accounting' },
  { sn: 11, name: 'JOYCE KIBONESE',  office: 'E-304', extension: 2051,department:'Human Resources and Administration',division:'Facilities Management' },
  { sn: 12, name: 'ANDREW NKUYE',  office: 'E-304', extension: 2056,department:'Human Resources and Administration',division:'Human Resources Management' },
  { sn: 13, name: 'MULONDO MORGAN',  office: 'E-307', extension: 2144,department:'Finance and Planning',division:'Finance and Accounting' },
  { sn: 14, name: 'SUZAN NAJJULU',  office: 'E-307', extension: 2118,department:'Human Resources and Administration',division:'Human Resources Management' },
  { sn: 15, name: 'MILLY NAKABUBI',  office: 'E-307', extension: 2174,department:'Human Resources and Administration',division:'Human Resources Management' },
  { sn: 16, name: 'LEON MARK SENYONDO',  office: 'E-303', extension: 2113,department:'Finance and Planning',division:'Procurement and Supply Management' },
  { sn: 17, name: 'HADIJAH BABIRYE',  office: 'E-307', extension: 2049,department:'Human Resources and Administration',division:'Human Resources Management' },
  { sn: 18, name: 'SUZAN KIHEMBO',  office: 'E-307', extension: 2142,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 19, name: 'ELIAS BWAMBALE',  office: 'E-207', extension: 2111,department:'Legal Affairs and Compliance',division:'N/A' },
  { sn: 20, name: 'JUSTUS MUTUNGI',  office: 'E-208', extension: 2010,department:'Audit',division:'Compliance Audit' },
  { sn: 21, name: 'RUTH NASEJJE',  office: 'E-204', extension: 2122,department:'Product Development',division:'Vehicle Integration' },
  { sn: 22, name: 'SANDRAH KOMUGISHA',  office: 'E-208', extension: 2048,department:'Legal Affairs and Compliance',division:'Legal Affairs' },
  { sn: 23, name: 'BRIDGE KUSEMERERWA',  office: 'E-208', extension: 2089,department:'Legal Affairs and Compliance',division:'Legal Affairs' },
  { sn: 24, name: 'SHABAN SENYANGE',  office: 'E-208', extension: 2114,department:'Marketing & Sales',division:'Corporate Affairs' },
  { sn: 25, name: 'JOAN EFRANCE MUKYALA',  office: 'E-208', extension: 2134,department:'Quality, Health and Safety',division:'Health and wellness' },
  { sn: 26, name: 'STELLA MARIS NASSAKA',  office: 'E-208', extension: 2137,department:'Audit',division:'Compliance Audit' },
  { sn: 27, name: 'JOHN GONSHIA',  office: 'E-202', extension: 2133,department:'Human Resources and Administration',division:'Facilities Management' },
  { sn: 28, name: 'SOPHY NALWANGA',  office: 'E-201', extension: 2110,department:'Human Resources and Administration',division:'Facilities Management' },
  { sn: 29, name: 'JEROME IVAN AYAZIKA',  office: 'E-106', extension: 2124,department:'Marketing and sales',division:'N/A' },
  { sn: 30, name: 'BOARDROOM',  office: 'E-101', extension: 2006,department:'N/A',division:'N/A' },
  { sn: 31, name: 'MOSES KALULE',  office: 'E-G03', extension: 2017, department:'Production',division:'Trim and Assembly Shop ' },
  { sn: 32, name: 'DEARN TUSIIME', office: 'E-G02', extension: 2132,department:'Human Resources and Administration',division:'Human Resources Management' },
  { sn: 33, name: 'DUES ASHABA',  office: 'E-G04', extension: 2096,department:'Production',division:'Chassis System' },
  { sn: 34, name: 'EMMANUEL KIRYA',  office: 'E-G02', extension: 2180,department:'Human Resources and Administration',division:'Facilities Management' },
  { sn: 35, name: 'BETTY KYALISIIMA',  office: 'E-G04', extension: 2067 ,department:'Production',division:'Trim and Assembly Shop' },
  { sn: 36, name: 'RECHEAL NIWAMANYA',  office: 'E-G04', extension: 2097 ,department:'Production',division:'Trim and Assembly Shop' },
  { sn: 37, name: 'ROBERT SSEMUGABI',  office: 'E-G05', extension: 2187 ,department:'Production',division:'Trim and Assembly Shop' },
  { sn: 38, name: 'NELSON TABU',  office: 'E-G05', extension: 2044,department:'Production',division:'Chassis System' },
  { sn: 39, name: 'JOHN BAIDU IREGE',  office: 'E-G05', extension: 2092,department:'Production',division:'Trim and Assembly Shop' },
  { sn: 40, name: 'ROBIN KOJO',  office: 'E-G05', extension: 2081, department:'Production',division:'Trim and Assembly Shop' },
  { sn: 41, name: 'ANDREW OGWAL',  office: 'E-G05', extension: 2106, department:'Production',division:'Trim and Assembly Shop' },
  { sn: 42, name: 'TONNY OLUKA',  office: 'E-G05', extension: 2028,department:'Production',division:'Trim and Assembly Shop' },
  { sn: 43, name: 'DANIEL AGEC',  office: 'E-G05', extension: 2117,department:'Production',division:'Trim and Assembly Shop' },
  { sn: 44, name: 'PERCY MALVIS KATO',  office: 'E-G05', extension: 2007,department:'Production',division:'Production Management' },
  { sn: 45, name: 'INFIRMARY TRIM',  office: 'E-G07', extension: 2127,department:'N/A',division:'N/A' },
  { sn: 46, name: 'RICHARD MADANDA',  office: 'W-G07', extension: 2103 ,department:'Product Development',division:'N/A' },
  { sn: 47, name: 'DOREEN TUSIIME',  office: 'W-G06', extension: 2087,department:'Product Development',division:'Vehicle intergration' },
  { sn: 48, name: 'BIMARK KYABANGI',  office: 'W-G06', extension: 2181,department:'Product Development',division:'Product Design and Engineering' },
  { sn: 49, name: 'GARY ZAKE',  office: 'W-G04', extension: 2090,department:'Product Development',division:'Product Design and Engineering' },
  { sn: 50, name: 'JOEL MULWANA',  office: 'W-G06', extension: 2108,department:'Product Development',division:'Product Design and Engineering' },
  { sn: 51, name: 'EMMANUEL GWOKTCHO',  office: 'W-G04', extension: 2189,department:'Product Development',division:'Product Design and Engineering' },
  { sn: 52, name: 'BRIAN ETWALU',  office: 'W-G04', extension: 2038,department:'Product Development',division:'Product Design and Engineering' },
  { sn: 53, name: 'JUDITH BULYABA',  office: 'W-G04', extension: 2055,department:'Product Development',division:'N/A' },
  { sn: 54, name: 'PHELBERT ABENAITWE',  office: 'W-G03', extension: 2053,department:'Product Development',division:'Vehicle Intergration' },
  { sn: 55, name: 'FREDRICK KATO',  office: 'W-G03', extension: 2145,department:'Product Development',division:'Vehicle Intergration' },
  { sn: 56, name: 'FRANCIS OMODING',  office: 'W-G03', extension: 2197,department:'Production',division:'Production Management' },
  { sn: 57, name: 'GABRIEL WOKORACH',  office: 'W-G03', extension: 2099,department:'Production',division:'Production Management' },
  { sn: 58, name: 'SIMON PETER ALUMAI',  office: 'W-G03', extension: 2188,department:'Product Development',division:'Vehicle Intergration' },
  { sn: 59, name: 'GABRIEL HERNIEL KITUTU',  office: 'W-G03', extension: 2072,department:'Product Development',division:'Vehicle Intergration' },
  { sn: 60, name: 'JAMES MUGABULA',  office: 'W-G03', extension: 2069,department:'Product Development',division:'Vehicle Intergration' },
  { sn: 61, name: 'JOANITA NAYIGA',  office: 'W-G03', extension: 2082,department:'Product Development',division:'Vehicle Intergration' },
  { sn: 62, name: 'JESSE T. NTWATWA TENDO',  office: 'W-G03', extension: 2155,department:'Product Development',division:'Vehicle Intergration' },
  { sn: 63, name: 'ROBERT NATUKUNDA',  office: 'W-G03', extension: 2083,department:'Product Development',division:'Vehicle Intergration' },
  { sn: 64, name: 'ANDREW NUWAMANYA',  office: 'W-G03', extension: 2139,department:'Product Development',division:'Vehicle Intergration' },
  { sn: 65, name: 'PETERSON KANYESIGYE',  office: 'W-G03', extension: 2185,department:'Product development',division:'Vehicle Intergration' },
  { sn: 66, name: 'DERRICK KAYEMBA',  office: 'WG03', extension: 2050, department:'Production',division:'Production management' },
  { sn: 67, name: 'STEPHEN LARII ODONG',  office: 'W-G03', extension: 2086,department:'Product development',division:'Vehicle Intergration' },
  { sn: 68, name: 'JORDON NYOMBI',  office: 'W-G04', extension: 2036,department:'Product Development',division:'Product Design and Engineering' },
  { sn: 69, name: 'HILDA NANGONZI',  office: 'W-G04', extension: 2011,department:'Product Development',division:'Product Design and Engineering' },
  { sn: 70, name: 'ELIZABETH NABUSIITA',  office: 'W-G04', extension: 2093,department:'Product Development',division:'Product Design and Engineering' },
  { sn: 71, name: 'ARTHUR KISUULE KUKURIZA',  office: 'W-G04', extension: 2035,department:'Product Development',division:'Product Design and Engineering' },
  { sn: 72, name: 'FRED MATOVU',  office: 'W-103', extension: 2091,department:'Product Development',division:'N/A' },
  { sn: 73, name: 'ADONIA MBAREBAKI',  office: 'W-105', extension: 2065,department:'Marketing and Sales',division:'Product Support' },
  { sn: 74, name: 'ALI ZIRYAWULAWO',  office: 'W-105', extension: 2120,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 75, name: 'JIMMY MUGISHA',  office: 'W-106', extension: 2076,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 76, name: 'GODFREY KIGULI', office: 'W-106', extension: 2029,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 77, name: 'BRIAN ASIIMWE',  office: 'W-106', extension: 2022,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 78, name: 'PETER MUWANGUZI',  office: 'W-106', extension: 2054,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 79, name: 'BURNETT KUSHABA',  office: 'W-106', extension: 2020,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 80, name: 'EMMANUEL JUSTUS AKANKUNDA',  office: 'W-106', extension: 2160, department:'Product Development',division:'EE Systems Engineering' },
  { sn: 81, name: 'LUCY LEAN SIINAKWOYO',  office: 'W-102', extension: 2128,department:'Product Development',division:'Powertrain Systems Engineering' },
  { sn: 82, name: 'DANIEL AGECH NYEKO',  office: 'W-102', extension: 2173,department:'Product Development',division:'Powertrain Systems Engineering' },
  { sn: 83, name: 'STUART NUWAGIIRABYONA',  office: 'W-102', extension: 2073,department:'Product Development',division:'Powertrain Systems Engineering' },
  { sn: 84, name: 'JESSE BAGANZI',  office: 'W-102', extension: 2074,department:'Product Development',division:'Powertrain Systems Engineering' },
  { sn: 85, name: 'GORDEN TWINAMATSIKO',  office: 'W-102', extension: 2184,department:'Product Development',division:'Powertrain Systems Engineering' },
  { sn: 86, name: 'DRUSCILAR ATUHAIRE',  office: 'W-102', extension: 2005,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 87, name: 'JOEL LWANGA',  office: 'W-102', extension: 2157,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 88, name: 'NICHOLAS MAYANJA',  office: 'W-102', extension: 2046,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 89, name: 'MARTHA AINOMUHWEZI',  office: 'W-102', extension: 2009,department:'Product Development',division:'Powertrain Systems Engineering' },
  { sn: 90, name: 'MONDAY SHADURACKA',  office: 'W-102', extension: 2084,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 91, name: 'JETHRO BASA MUKISA KIGOBE',  office: 'W-102', extension: 2146,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 92, name: 'JADE KINOBE SSEWAGUDDE',  office: 'W-102', extension: 2129,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 93, name: 'GILBERT NATUSASIIRA',  office: 'W-102', extension: 2179,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 94, name: 'KAREN DORCAS NANYONGA',  office: 'W-102', extension: 2042, department:'Product Development',division:'EE Systems Engineering' },
  { sn: 95, name: 'BENJAMIN MODIKAYO OJEDE',  office: 'W-102', extension: 2195,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 96, name: 'ROSHAN KYASIMIRE',  office: 'W-102', extension: 2121,department:'Product Development',division:'EE Systems Engineering' },
  { sn: 97, name: 'JONATH NATWIJUKA',  office: 'W-102', extension: 2182,department:'Product Development',division:'Powertrain Engineering' },
  { sn: 98, name: 'ESTHER PATRICIA NYABEL',  office: 'W-102', extension: 2063,department:'Product Development',division:'Powertrain Engineering' },
  { sn: 99, name: 'JOHN CONRAD NSUBUGA',  office: 'W-106', extension: 2021,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 100, name: 'PHIONA NYANGOMA',  office: 'W-106', extension: 2064,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 101, name: 'COLLIN ABIGABA',  office: 'W-106', extension: 2075,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 102, name: 'JOSEPH ARINAITWE',  office: 'W-106', extension: 2066,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 103, name: 'DESTINY SANYU MULUNGI',  office: 'W-106', extension: 2119,department:'Product Development',division:'Information Systems Engineering' },
  { sn: 104, name: 'IAN KAVUMA', office: 'PQ -101', extension: 2167,department:'Quality,health and safety',division:'N/A' },
  { sn: 105, name: 'JOB MASEDE',  office: 'PQ-102', extension: 2078,department:'Quality,health and safety',division:'Quality Inspection and Testing' },
  { sn: 106, name: 'OWEN TUSIIME',  office: 'PQ-102', extension: 2171,department:'Quality,health and safety',division:'Quality Inspection and Testing' },
  { sn: 107, name: 'NELSON KISOLO',  office: 'PQ-103', extension: 2168,department:'Quality,health and safety',division:'Quality Inspection and Testing' },
  { sn: 108, name: 'DANIEL ENYAGU',  office: 'PQ-103', extension: 2136,department:'Quality,health and safety',division:'Quality Inspection and Testing' },
  { sn: 109, name: 'TIMOTHY KAIRU',  office: 'PQ-103', extension: 2192,department:'Quality,health and safety',division:'Quality Inspection and Testing' },
  { sn: 110, name: 'INNOCENT NATURINDA',  office: 'PQ-103', extension: 2177,department:'Quality,health and safety',division:'Quality Inspection and Testing' },
  { sn: 111, name: 'CALVIN OTIM',  office: 'PQ-103', extension: 2109,department:'Quality,health and safety',division:'Quality Inspection and Testing' },
  { sn: 112, name: 'ALBERT AKOVUKU',  office: 'PW-109', extension: 2101,department:'Production',division:'N/A' },
  { sn: 113, name: 'FESTO NIMURUNGI',  office: 'PW-104', extension: 2071,department:'Production',division:'N/A' },
  { sn: 114, name: 'FRED KAYEMBA', office: 'PW-103', extension: 2016,department:'Production',division:'Chassis System' },
  { sn: 115, name: 'STEVEN KATO',  office: 'PW-101', extension: 2186,department:'Production',division:'Chassis System' },
  { sn: 116, name: 'MATHIAS KASUMBA',  office: 'PW-101', extension: 2135,department:'Production',division:'Chassis System' },
  { sn: 117, name: 'VICTOR BWANATAI',  office: 'PW-101', extension: 2143,department:'Production',division:'Chassis System' },
  { sn: 118, name: 'PETER OCOM',  office: 'PW-101', extension: 2172,department:'Production',division:'Chassis System' },
  { sn: 119, name: 'VICENT WANTANTE MUSITWA',  office: 'PW-102', extension: 2164,department:'Production',division:'Electropherisis & Paint Shops' },
  { sn: 120, name: 'MUSA KAYONDO',  office: 'PW-102', extension: 2161,department:'Production',division:'Electropherisis & Paint Shops' },
  { sn: 121, name: 'GODFREY BBEMBA',  office: 'PE-106', extension: 2105,department:'Production',division:'Body Shop' },
  { sn: 122, name: 'TIMOTHY MUHUMUZA',  office: 'PE-103', extension: 2080,department:'Production',division:'Body Shop' },
  { sn: 123, name: 'ONESIMUS IRUNVA',  office: 'PE-103', extension: 2060,department:'Production',division:'Body Shop' },
  { sn: 124, name: 'CAROLINE KIIZA',  office: 'PE-103', extension: 2014,department:'Production',division:'Body Shop' },
  { sn: 125, name: 'DESMOND KIIZA',  office: 'PE-103', extension: 2013,department:'Production',division:'Body Shop' },
  { sn: 126, name: 'ANDREW NIYONZIMA',  office: 'PE-103', extension: 2150, department:'Production',division:'Body Shop' },
  { sn: 127, name: 'WILLIAM MUHANGUZI',  office: 'PE-103', extension: 2156,department:'Production',division:'Body Shop' },
  { sn: 128, name: 'JAMES PETER ONAI',  office: 'PE-104', extension: 2107,department:'Production',division:'Body Shop' },
  { sn: 129, name: 'SIMON WAKUNYAGA',  office: 'PE-106', extension: 2151,department:'Production',division:'Chassis System' },
  { sn: 130, name: 'INFIRMARY BODY SHOP',  office: 'INFIRMARY', extension: 2025,department:'N/A',division:'N/A' },
  { sn: 131, name: 'WILLY KATENDE',  office: 'WH-G07', extension: 2047,department:'Production',division:'Production Management' },
  { sn: 132, name: 'REAGAN JOSEPH NGANZI',  office: 'WH-G05', extension: 2039,department:'Production',division:'Production Management' },
  { sn: 133, name: 'ALLAN KIJEWA',  office: 'WH-G06', extension: 2162,department:'Production',division:'Production Management' },
  { sn: 134, name: 'RICHARD KAZIMBYE',  office: 'WH-G06', extension: 2130,department:'Production',division:'Production Management' },
  { sn: 135, name: 'EDWARD MULINDE',  office: 'WH-G05', extension: 2037,department:'Production',division:'Production Management' },
  { sn: 136, name: 'GODFREY KIBERU',  office: 'WH-G05', extension: 2085,department:'Production',division:'Production Management' },
  { sn: 137, name: 'JOHSON WAMALA',  office: 'WH-G06', extension: 2152,department:'Production',division:'Production Management' },
  { sn: 138, name: 'ROLLAND NUWAGABA',  office: 'WH-G06', extension: 2079,department:'Human Resources and Administration',division:'Facilities Management' }
  
];

const PhoneExtensions: React.FC = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>('');
  const [filteredData, setFilteredData] = useState<PhoneEntry[]>(ipPhoneData);
  const [sortField, setSortField] = useState<keyof PhoneEntry | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    let data = ipPhoneData.filter(
      (entry) =>
        entry.name.toLowerCase().includes(filter.toLowerCase()) 
    );

    if (sortField) {
      data.sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setFilteredData(data);
  }, [filter, sortField, sortDirection]);

  const handleSort = (field: keyof PhoneEntry) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const maxPageNumbers = 5;
    const half = Math.floor(maxPageNumbers / 2);
    let start = Math.max(currentPage - half, 1);
    let end = Math.min(start + maxPageNumbers - 1, totalPages);
  
    if (end - start < maxPageNumbers - 1) {
      start = Math.max(end - maxPageNumbers + 1, 1);
    }
  
    return Array.from({ length: end - start + 1 }, (_, idx) => start + idx);
  };

  return (
<div className="p-4 bg-gray-100 min-h-screen overflow-x-hidden">
      <div className='w-full h-fit flex flex-row gap-4 justify-between items-center px-2 py-4 mt-2'>
                <div className="w-fit h-fit">
                    <img
                    src="/New KMC Logo 1.png"
                    onClick={() => navigate('/')}
                    alt="Logo"
                    className="h-5 w-auto cursor-pointer"
                    />
                </div>
      </div>
<div className="relative w-320 bg-red-600 rounded-lg p-6 mb-6 sm:w-screen">
  <header className="flex items-center mb-8">
    <h1 className="text-3xl font-bold text-white sm:text-lg">IP Phone Extensions</h1>
  </header>
  <div className="relative w-full mb-6">
    <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
    <input
      type="text"
      placeholder="Search for an employee by name or position acronym..."
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      className="w-[90%] p-3 pl-10 border border-gray-300 rounded-full shadow-sm focus:ring-0 focus:border-0 focus:outline-0 sm:w-[95%]"
    />
  </div>
</div>

      <div className="flex gap-4 mb-4">
        {/* <button
          className={`px-4 py-2 rounded-lg shadow transition-colors duration-200 hover:bg-red-500 hover:text-white ${
            sortField === 'acronym' ? 'bg-red-600 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleSort('acronym')}
        >
          Position Acronym
        </button> */}
        <button
          className={`px-4 py-2 rounded-full shadow focus:ring-0 focus:border-0 focus:outline-0 transition-colors duration-200 hover:bg-red-500 hover:text-white ${
            sortField === 'division' ? 'bg-red-600 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleSort('division')}
        >
          Division
        </button>
        <button
          className={`px-4 py-2 rounded-full shadow focus:ring-0 focus:border-0 focus:outline-0 transition-colors duration-200 hover:bg-red-500 hover:text-white ${
            sortField === 'department' ? 'bg-red-600 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleSort('department')}
        >
          Department
        </button>
        <button
          className={`px-4 py-2 rounded-full shadow focus:ring-0 focus:border-0 focus:outline-0 transition-colors duration-200 hover:bg-red-500 hover:text-white ${
            sortField === 'sn' ? 'bg-red-600 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleSort('sn')}
        >
          All Extensions
        </button>
        {/* <button
          className={`px-4 py-2 rounded-lg shadow transition-colors duration-200 hover:bg-red-500 hover:text-white ${
            sortField === 'department' ? 'bg-red-600 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleSort('department')}
        >
          Departments
        </button>
        <button
          className={`px-4 py-2 rounded-lg shadow transition-colors duration-200 hover:bg-red-500 hover:text-white ${
            sortField === 'division' ? 'bg-red-600 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleSort('division')}
        >
          Divisions
        </button> */}

      </div>
      <div className={`md:w-full w-320 overflow-x-scroll sm:overscroll-x-auto`}>
        <table className="w-full bg-white rounded-xl shadow-md min-w-full bg-white rounded-xl overflow-x-scroll touch-auto scrollable-hover">
          <thead className="bg-gray-200 rounded-full">
            <tr className='rounded-xl'>
              <th className="p-3 rounded-tl-xl text-left cursor-pointer hidden sm:table-cell" onClick={() => handleSort('sn')}>
                S/N {sortField === 'sn' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="p-3 text-left cursor-pointer" onClick={() => handleSort('name')}>
                Employee Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              {/* <th className="p-3 text-left cursor-pointer" onClick={() => handleSort('acronym')}>
                Position Acronym {sortField === 'acronym' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th> */}
              <th className="p-3  text-left cursor-pointer" onClick={() => handleSort('office')}>
                Office Number {sortField === 'office' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="p-3 text-left cursor-pointer" onClick={() => handleSort('extension')}>
                Extension Code {sortField === 'extension' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="p-3 text-left cursor-pointer" onClick={() => handleSort('department')}>
                Department {sortField === 'department' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th className="p-3 rounded-tr-xl text-left cursor-pointer" onClick={() => handleSort('division')}>
                Division {sortField === 'division' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
            </tr>
          </thead>
          <tbody className="rounded-xl">
            {paginatedData.map((entry) => (
              <tr key={entry.sn} className="border-b">
                <td className="p-3 hidden sm:table-cell">{entry.sn}</td>
                <td className="p-3">{entry.name}</td>
                {/* <td className="p-3">{entry.acronym}</td> */}
                <td className="p-3">{entry.office}</td>
                <td className="p-3">{entry.extension}</td>
                <td className="p-3">{entry.department}</td>
                <td className="p-3">{entry.division}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <footer className="flex justify-center gap-2 mt-6">
  {/* Previous Arrow */}
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    className={`px-3 py-1 focus:ring-0 focus:border-0 focus:outline-0 rounded ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'bg-white-200'}`}
    disabled={currentPage === 1}
    aria-label="Previous Page"
  >
    &laquo;
  </button>

  {/* Page Numbers */}
  {getPageNumbers().map((page) => (
    <button
      key={page}
      onClick={() => handlePageChange(page)}
      className={`px-3 py-1 focus:ring-0 focus:border-0 focus:outline-0 rounded ${currentPage === page ? 'bg-red-600 text-white' : 'bg-white-200'}`}
      aria-label={`Go to page ${page}`}
    >
      {page}
    </button>
  ))}

  {/* Next Arrow */}
  <button
    onClick={() => handlePageChange(currentPage + 1)}
    className={`px-3 py-1 focus:ring-0 focus:border-0 focus:outline-0 rounded ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : 'bg-white-200'}`}
    disabled={currentPage === totalPages}
    aria-label="Next Page"
  >
    &raquo;
  </button>
</footer>

    </div>
  );
};

export default PhoneExtensions;
